<template>
    <div v-if="dataSource" class="p-relative">

        <Toolbar 
            :menu="dataSource.Data.Menu" 
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab>
                {{ $t("Карточка") }}
            </v-tab>

        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item>
                <v-form class="wrapperForm" ref="form" lazy-validation>
                    <v-row dense>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="6" class="section-wrapper">
                            <!--Блок Сведения о регистрации-->
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{$t("Контрагент")}}</div>

                                    <!--поле Наименование-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Наименование")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.Name ? dataSource.Data.Object.Name : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                v-model="dataSourceName"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                :rules="requiredRule"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!--поле Краткое наименование-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Краткое_наименование")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.ShortName ? dataSource.Data.Object.ShortName : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                v-model="dataSourceShortName"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!--поле Резидент РК-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Резидент_рк")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.IsResident ? $t("Да") : $t("Нет") }}
                                            </div>
                                            <v-switch
                                                v-else
                                                v-model="dataSource.Data.Object.IsResident"
                                                inset
                                                hide-details
                                                class="cust-switch"
                                            />
                                        </v-col>
                                    </v-row>

                                    <!--поле БИН-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("БИН")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.INN ? dataSource.Data.Object.INN : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                v-model="dataSource.Data.Object.INN"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!--поле Отрасль-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Отрасль")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{
                                                    dataSource.Data.Object.Sector != null && dataSource.Data.Object.Sector !== 0
                                                        ? industries.reduce( function (names, industry) {
                                                                if ( (industry.id & dataSource.Data.Object.Sector) > 0) {
                                                                    names.push(`"${industry.Value}"`);
                                                                }
                                                                return names;
                                                            }, []).join(", ")
                                                        : $t("Не_указано")
                                                }}
                                            </div>
                                            <v-select
                                                v-else
                                                :items="industries"
                                                multiple
                                                item-value="id"
                                                item-text="Value"
                                                :value="getSectorValues"
                                                @input="getSector($event)"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="6" class="section-wrapper">
                            <!--Блок Сведения о регистрации-->
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{$t("Дополнительные_сведения")}}</div>

                                    <!--поле Министерство-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Министерство")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.AdditionalObjects[0].IsMinisterstvo ? $t("Да") : $t("Нет") }}
                                            </div>
                                            <v-switch
                                                v-else
                                                v-model="dataSource.Data.AdditionalObjects[0].IsMinisterstvo"
                                                @change="onIsMinisterstvoChanged($event)"
                                                inset
                                                hide-details
                                                class="cust-switch"
                                            />
                                        </v-col>
                                    </v-row>

                                    <!--поле Областные_департаменты__управления_и_комитеты-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Областные_департаменты__управления_и_комитеты")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.AdditionalObjects[0].IsOblOrgan ? $t("Да") : $t("Нет") }}
                                            </div>
                                            <v-switch
                                                v-else
                                                v-model="dataSource.Data.AdditionalObjects[0].IsOblOrgan"
                                                @change="onIsOblOrganChanged($event)"
                                                inset
                                                hide-details
                                                class="cust-switch"
                                            />
                                        </v-col>
                                    </v-row>

                                    <!--поле Акиматы_районов_и_города-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Акиматы_районов_и_города")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.AdditionalObjects[0].IsRegionAkimat ? $t("Да") : $t("Нет") }}
                                            </div>
                                            <v-switch
                                                v-else
                                                v-model="dataSource.Data.AdditionalObjects[0].IsRegionAkimat"
                                                @change="onIsRegionAkimatChanged($event)"
                                                inset
                                                hide-details
                                                class="cust-switch"
                                            />
                                        </v-col>
                                    </v-row>

                                    <!--поле Областные_тоо__ао__оао-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Областные_тоо__ао__оао")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.AdditionalObjects[0].IsOblBusiness ? $t("Да") : $t("Нет") }}
                                            </div>
                                            <v-switch
                                                v-else
                                                v-model="dataSource.Data.AdditionalObjects[0].IsOblBusiness"
                                                @change="onIsOblBusinessChanged($event)"
                                                inset
                                                hide-details
                                                class="cust-switch"
                                            />
                                        </v-col>
                                    </v-row>

                                    <!--поле Идентификатор_в_атласе-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Идентификатор_в_атласе")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                {{ dataSource.Data.Object.AtlasId ? dataSource.Data.Object.AtlasId : $t("Не_указано") }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Контакты") }}</div>
                                    
                                    <div class="onlyReadData more-per-lab-wrap chips-without-label">
                                        <v-contact-item
                                            v-for="contact in contacts.filter(x => !x.DelRec)"
                                            :key="contact.id"
                                            :type="contact.__type === 'ContactPhoneNew:#Avrora.Objects.Common' ? 'phone': 'email'"
                                            :title="contact.Value"
                                            :description="contact.Description"
                                            :hide-load="true"
                                            :is-read-only="isViewMode"
                                            @editcontact="onEditContact" 
                                            @deletecontact="onDeleteContact"
                                        />

                                        <v-chip
                                            class="person-label immutable-text"
                                            small
                                            label
                                            outlined
                                            v-if="!isViewMode"
                                            @click="onAddContact"
                                        >
                                            <i class="fas fa-plus dark-gray-color"></i>
                                            <div>{{ $t("Добавить_контакт") }}</div>
                                        </v-chip>

                                        <div 
                                            v-if="isViewMode && contacts.length == 0"
                                            class="cfl-placeholder"
                                        >
                                            {{ $t("Контакты_не_найдены") }}
                                        </div>

                                    </div>
                                        
                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-form>
            </v-tab-item>

        </v-tabs-items>

        <ContactsDlg ref="ContactsRef"
            @on-save-contact="onSaveContact"
        />

    </div>
</template>

<script>
import i18n from '@/i18n';
import { mapActions, mapGetters } from 'vuex';
import Toolbar from '@/components/Toolbar.vue';
import ContactsDlg from '@/components/dialogs/ContactsDlg'
import { httpAPI } from "@/api/httpAPI";
import sys from '@/services/system';

export default {
    name: "ContractorEnterprise",
    props: {
        id: { type: String, required: true }
    },
    components: {
       Toolbar,
       ContactsDlg
    },
    data: () => ({
        tab: null,
        dataSource: null,
        industries: [
            { id: 0x01, Value: i18n.t("Промышленность") },
            { id: 0x02, Value: i18n.t("Сельское_хозяйство") },
            { id: 0x04, Value: i18n.t("Лесное_хозяйство") },
            { id: 0x08, Value: i18n.t("Транспорт") },
            { id: 0x10, Value: i18n.t("Связь") },
            { id: 0x20, Value: i18n.t("Строительство") },
            { id: 0x40, Value: i18n.t("Торговля_и_питание") },
            { id: 0x80, Value: i18n.t("Материально_техническое_обеспечение_производства__") },
            { id: 0x100, Value: i18n.t("Информационно_вычислительное_обслуживание") },
            { id: 0x200, Value: i18n.t("Общая_коммерческая_деятельность") },
            { id: 0x400, Value: i18n.t("Геология__геодезия") },
            { id: 0x800, Value: i18n.t("Жилищное_хозяйство") },
            { id: 0x1000, Value: i18n.t("Коммунальное_хозяйство") },
            { id: 0x2000, Value: i18n.t("Здравоохранение_и_физическая_культура") },
            { id: 0x4000, Value: i18n.t("Образование") },
            { id: 0x8000, Value: i18n.t("Культура_и_искусство") },
            { id: 0x10000, Value: i18n.t("Наука") },
            { id: 0x20000, Value: i18n.t("Финансирование__кредитование__страхование") },
            { id: 0x40000, Value: i18n.t("Управление") },
            { id: 0x80000, Value: i18n.t("Государственная_собственность") }
        ]
    }),
    computed: {
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
        }),
        isViewMode() {
            return this.dataSource?.Data.FormId === "0101021";
        },
        getSectorValues() {
            let sector = this.dataSource.Data.Object.Sector;
            return this.industries.reduce( function (keys, industry) {
                if ( (industry.id & sector) > 0) {
                    keys.push(industry.id);
                }
                return keys;
            }, []);
        },
        contacts() {
            if (this.dataSource?.Data?.Object?.ContactList == null)
                return [];
            
            return this.dataSource.Data.Object.ContactList.data_list;
        },
        dataSourceName: {
            get: function() {
                if (this.dataSource.Data.Object.UniName)
                    return this.dataSource.Data.Object.UniName["ru-RU"];
                
                return "";
            },
            set: function(value) {
                this.dataSource.Data.Object.UniName["ru-RU"] = value;
            }
        },
        dataSourceShortName: {
            get: function() {
                if (this.dataSource.Data.Object.UniShortName)
                    return this.dataSource.Data.Object.UniShortName["ru-RU"];
                
                return "";
            },
            set: function(value) {
                this.dataSource.Data.Object.UniShortName["ru-RU"] = value;
            }
        },
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        async getData(){
            let response = await httpAPI({
                url: this.id === 'new'
                    ? `api/new?type=Chancellery.ContractorEnterprise`
                    : `api/open?type=Chancellery.ContractorEnterprise&id=${this.dataSource.Data.Object.id}`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            if (response) {
                this.dataSource = response.data.payload;
            }
        },
        //#region Действия (тулбар)
        async onToolbarClick (event, button) {
            let action = this[button.Action];
            
            if (action && typeof action === 'function') {
                if (button.Action == 'Save' || button.Action == 'SaveAndClose') {
                    let validate_result = this.$refs.form.validate();

                    if (validate_result) {
                        await action(event, button);
                    }
                    else {
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                    }
                }
                else {
                    await action(event, button);
                }
            }
            else
                this.$notify.alert(`Действие ${button.Action} не реализовано.`);
        },
        async Edit() {
            let response = await httpAPI({
                url: `api/edit?type=Chancellery.ContractorEnterprise&id=${this.dataSource ? this.dataSource.Data.Object.id : this.id}`,
                method: 'GET'
            });

            if (response) {
                this.dataSource.Data.Menu = response.data.payload.Data.Menu;
                this.dataSource.Data.FormId = response.data.payload.Data.FormId;
            }
        },
        async ChangeType() {
            if (this.dataSource){
                let response = await httpAPI({
                    url: `api/ems/enterprisecreatefromcontractor?id=${this.dataSource.Data.Object.id}`,
                    method: 'GET'
                });

                if (response) {
                    this.$store.commit('emsCards/enterpriseCard/SET_ACTIVE_TAB', 0 );
                    this.$store.commit('emsCards/enterpriseCard/SET_OPEN_STRUCT_NODE', [] );
                    this.$router.replace({ name: 'EMSEnterpriseCard', query: { id: this.dataSource.Data.Object.id }, params: { id: this.dataSource.Data.Object.id } });
                }
            }
        },
        async SaveAndClose() {
            this.setOverlayVisible({ visible: true });

            let response = await httpAPI({
                url: `api/save?type=Chancellery.ContractorEnterprise`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { Content : JSON.stringify({ parent: this.dataSource.Data.Object, childrens: this.dataSource.Data.AdditionalObjects }) },
            });

            if (response) {
                this.dataSource = response.data.payload;
                this.$notify.success(response.data.payload?.Message ?? response.data.message);
            }

            this.setOverlayVisible({ visible: false });
        },
        getSector(valuesArray) {
            this.dataSource.Data.Object.Sector = valuesArray.reduce( function (sectorValue, industry) {
                return sectorValue | industry;
            }, 0);
        },
        //#endregion
        //#region Работа с контактами
        async onAddContact() {
            await this.openContactDialog(null);
        },
        async onSaveContact(contact) {
            if (!this.dataSource.Data.Object.ContactList)
                this.dataSource.Data.Object.ContactList = { data_list: []};
            let contacts = JSON.parse(JSON.stringify(this.dataSource.Data.Object.ContactList.data_list));
            
            if (contact.isNew) {
                let newContact =
                {
                    __type: contact.type === 'phone' ? "ContactPhoneNew:#Avrora.Objects.Common" : "ContactEMailNew:#Avrora.Objects.Common",
                    DelRec: false,
                    Type: contact.type === 'phone' ? 2 : 1,
                    Value: contact.value,
                    Description: contact.description,
                    id: sys.generateUUID(),
                    is_new_record: true
                };
                contacts.push(newContact);
            }
            else {
                let existedContact = contacts.find(x => x.id === contact.id);
                
                if (existedContact) {
                    existedContact.__type =  contact.type === 'phone' ? "ContactPhoneNew:#Avrora.Objects.Common" : "ContactEMailNew:#Avrora.Objects.Common",
                    existedContact.Value = contact.value;
                    existedContact.Description = contact.description;
                }
            }
            this.dataSource.Data.Object.ContactList.data_list = contacts;
        },
        async onEditContact(contactId) {
            let existedContact = this.dataSource.Data.Object.ContactList.data_list.find(x => x.id === contactId);

            if (existedContact)
                await this.openContactDialog({
                    id: existedContact.id,
                    type: existedContact.__type === 'ContactPhoneNew:#Avrora.Objects.Common' ? 'phone': 'email',
                    value: existedContact.Value,
                    description: existedContact.Description
                });
        },
        async onDeleteContact(contactId) {
            let existedContact = this.dataSource.Data.Object.ContactList.data_list.find(x => x.id === contactId);

            if (existedContact)
                existedContact.DelRec = true;
        },
        async openContactDialog(contact) {
            try {
                await this.$refs.ContactsRef.open(contact);
            } 
            catch (ex) {
                console.log(ex);
            }
        },
        //#endregion
        //#region Работа с Типом организации
        onIsMinisterstvoChanged(event){
            this.dataSource.Data.AdditionalObjects[0].IsMinisterstvo = event;
            if (event)
            {
                this.dataSource.Data.AdditionalObjects[0].IsOblOrgan = false;
                this.dataSource.Data.AdditionalObjects[0].IsRegionAkimat = false;
                this.dataSource.Data.AdditionalObjects[0].IsOblBusiness = false;
            }
        },
        onIsOblOrganChanged(event){
            this.dataSource.Data.AdditionalObjects[0].IsOblOrgan = event;
            if (event)
            {
                this.dataSource.Data.AdditionalObjects[0].IsMinisterstvo = false;
                this.dataSource.Data.AdditionalObjects[0].IsRegionAkimat = false;
                this.dataSource.Data.AdditionalObjects[0].IsOblBusiness = false;
            }
        },
        onIsRegionAkimatChanged(event){
            this.dataSource.Data.AdditionalObjects[0].IsRegionAkimat = event;
            if (event)
            {
                this.dataSource.Data.AdditionalObjects[0].IsMinisterstvo = false;
                this.dataSource.Data.AdditionalObjects[0].IsOblOrgan = false;
                this.dataSource.Data.AdditionalObjects[0].IsOblBusiness = false;
            }
        },
        onIsOblBusinessChanged(event){
            this.dataSource.Data.AdditionalObjects[0].IsOblBusiness = event;
            if (event)
            {
                this.dataSource.Data.AdditionalObjects[0].IsMinisterstvo = false;
                this.dataSource.Data.AdditionalObjects[0].IsOblOrgan = false;
                this.dataSource.Data.AdditionalObjects[0].IsRegionAkimat = false;
            }
        },
        //#endregion
    },
    updated() {
        if(this.dataSource !== null){
            this.$refs?.form?.validate();
        }  
    },
    async created() {        
        (async() => {
            //await this.getData();
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs.form.validate();
        })();
    },
    beforeRouteEnter(to, from, next) {
        httpAPI({
            url: to.query.id === 'new'
                    ? `api/new?type=Chancellery.ContractorEnterprise`
                    : `api/open?type=Chancellery.ContractorEnterprise&id=${to.query.id}`,
            method: 'GET'
        })
        .then(response => {
            if (response)
                next(vm => { vm.dataSource = response.data.payload; });

            next(false);
        });
    }
}
</script>